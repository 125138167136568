import Head from "next/head";
import React, { useState } from "react";
import Image from "next/image";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import Link from "next/link";
import Fade from "react-reveal/Fade";
import { firebaseApp } from "@/scripts/firebaseApp";
import Carousel, {
  autoplayPlugin,
  slidesToShowPlugin
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

export default function HomePage() {
  const defaultState = {
    isVideoOneOpen: false,
    isVideoTwoOpen: false,
    formData: {
      name: "",
      phone: "",
      email: "",
      message: ""
    },

    msgSubmitted: false
  };

  const [state, setState] = useState(defaultState);

  const reactPlayerStyles = {
    content: {
      width: "70%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      border: "0px solid lightgray",
      backgroundColor: "rgba(0,0,0,0)",
      transform: "translate(-50%, -50%)"
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,0.9)"
    }
  };

  return (
    <>
      <Head>
        <meta
          key="twitter:title"
          name="twitter:title"
          content="Twurs | Travel Management and distribution systems for tour and hospitality operators"
        />
        <meta
          key="og:title"
          property="og:title"
          content="Twurs | Travel Management and distribution systems for tour and hospitality operators"
        />
        <meta
          name="title"
          content="Twurs | Travel Management and distribution systems for tour and
          hospitality operators"
        />
        <meta
          name="description"
          content="Feature and sell tour and hospitality products with a single, automated connection, optimized for travelers and agents - complete software and backend solution for dynamic pricing, yield management, manifest, supplier and user management."
        />
      </Head>
      <Modal
        isOpen={state.isVideoOneOpen}
        onRequestClose={() =>
          setState(state => ({ ...state, isVideoOneOpen: false }))
        }
        style={reactPlayerStyles}
        contentLabel="Video Modal"
      >
        <ReactPlayer
          width="100%"
          height="100%"
          controls={true}
          playing={true}
          url="https://firebasestorage.googleapis.com/v0/b/twurs-com.appspot.com/o/website%2Fprod-vid.mp4?alt=media&token=2d41bf96-53f2-4a74-b9cf-90c1261715d4"
        />
      </Modal>
      <Modal
        isOpen={state.isVideoTwoOpen}
        onRequestClose={() =>
          setState(state => ({ ...state, isVideoTwoOpen: false }))
        }
        style={reactPlayerStyles}
        contentLabel="Video Modal"
      >
        <ReactPlayer
          width="100%"
          height="100%"
          controls={true}
          playing={true}
          url="https://firebasestorage.googleapis.com/v0/b/twurs-com.appspot.com/o/videos%2FInvestor-Video-V2.mp4?alt=media&token=88697481-8e1d-46a8-8e9e-d5f42cb5e359"
        />
      </Modal>

      <section className="hidden lg:block">
        {typeof window !== "undefined" ? (
          <Carousel
            plugins={[
              "infinite",
              "fastSwipe",
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1
                }
              },
              {
                resolve: autoplayPlugin,
                options: {
                  interval: 3500
                }
              }
            ]}
            animationSpeed={1000}
          >
            <div className="w-full ">
              {" "}
              <HeroSection
                title={"Distribute your travel products globally"}
                description={
                  "Next-gen solutions that digitalise tour products and connect tour agents and operators to global customers"
                }
                imgg={`hero-img.png`}
              />
            </div>

            <div className="w-full ">
              {" "}
              <HeroSection
                title={"Save and get more done in less time"}
                description={
                  "Better control of your inventory and extra time to focus on your customers and products"
                }
                imgg={`hero-img-2.png`}
              />
            </div>

            <div className="w-full ">
              {" "}
              <HeroSection
                title={"Travel tech solutions for multi-day tours"}
                description={
                  "Aggregate and connect to thousands of multi-day tours globally through a single API"
                }
                imgg={`hero-img-3.png`}
              />
            </div>
          </Carousel>
        ) : null}
      </section>

      <section className="block lg:hidden">
        <HeroSection
          title={"Distribute your travel products globally"}
          description={
            "Next-gen solutions that digitalise tour products and connect tour agents and operators to global customers"
          }
          imgg={`hero-img.png`}
        />
      </section>

      <section className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <Fade top>
            <h1 className="max-w-lg mb-6  text-gray-900 section-heading md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute  top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-primary-300 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="9a29985a-fc16-419b-ae53-1670f5ca4491"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#9a29985a-fc16-419b-ae53-1670f5ca4491)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative"> All-In-One</span>
              </span>{" "}
              Platform
            </h1>

            <p className="section-para font-semibold text-gray-600 mb-1 ">
              Improve Productivity . Increase Revenue . Save Money & Time
            </p>
            <p className="section-para   ">
              Automate routine operational tasks & get more done in less time
            </p>
          </Fade>
        </div>

        <div className="grid max-w-screen-lg gap-8 row-gap-5 mb-8 sm:grid-cols-2 sm:mx-auto">
          <Fade left>
            <div className="col-span-1 flex items-center h-full justify-center">
              <img
                onClick={() =>
                  setState(state => ({ ...state, isVideoOneOpen: true }))
                }
                className="rounded-md shadow-lg  cursor-pointer"
                src="https://firebasestorage.googleapis.com/v0/b/twurs-com.appspot.com/o/website%2Fvid-thumbnail.jpg?alt=media&token=70f0ad63-1e15-4249-9d92-f8f3b29715a2"
              />
              <div
                onClick={() =>
                  setState(state => ({ ...state, isVideoOneOpen: true }))
                }
                className="absolute cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-20 w-20 text-primary-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className="col-span-1 flex items-center h-full max-h-72 justify-center">
              <img
                onClick={() =>
                  setState(state => ({ ...state, isVideoTwoOpen: true }))
                }
                className="rounded-md shadow-lg h-full cursor-pointer"
                src="https://firebasestorage.googleapis.com/v0/b/twurs-com.appspot.com/o/images%2Fthumbnail-vid-2.JPG?alt=media&token=0a37fe5c-6716-4984-8e8f-cd7e935b66a4"
              />
              <div
                onClick={() =>
                  setState(state => ({ ...state, isVideoTwoOpen: true }))
                }
                className="absolute cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-20 w-20 text-primary-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="lg:pr-10">
            <Fade left>
              <a className="inline-block mb-5">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-primary-100">
                  <svg
                    className="w-10 h-10 text-primary-700"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
              </a>
            </Fade>
            <Fade left>
              <h1 className="mb-4 section-heading">Ultimate Tool</h1>
            </Fade>
            <Fade left>
              <p className="mb-6 mt-6 section-para">
                We deliver real results to operators by opening new global
                distribution and marketing channels through our API connectivity
              </p>

              <div className="flex items-center space-x-4">
                <Link href={"/suppliers"}>
                  <button className="inline-flex items-center font-semibold transition-colors duration-200 text-primary-600 ">
                    Learn more
                  </button>
                </Link>
              </div>
            </Fade>
          </div>{" "}
          <Fade right>
            <div>
              <Image
                src={`/images/44.png`}
                priority={true}
                height={400}
                width={800}
                className="shadow-lg  rounded-md "
              />
            </div>
          </Fade>
        </div>
      </section>
      <section className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2">
          <Fade left>
            <div className="hidden   lg:block">
              <Image
                src={`/images/api.png`}
                priority={true}
                height={400}
                width={800}
                className="rounded-md shadow-lg"
              />
            </div>
          </Fade>

          <div className="lg:pr-10">
            <Fade right>
              <a className="inline-block mb-5">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-primary-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-primary-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
                    />
                  </svg>
                </div>
              </a>
            </Fade>

            <Fade right>
              <h1 className="mb-4 section-heading ">Digitalize and Scale</h1>
            </Fade>
            <Fade right>
              <p className="mb-6 mt-6 section-para">
                Streamline your tour business with our cloud-based reservation
                system. Feature and sell thousands of tour products on your
                website with a single automated API connection
              </p>

              <div className="flex items-center space-x-4">
                <Link href={"/resellers"}>
                  <button className="inline-flex items-center font-semibold transition-colors duration-200 text-primary-600 ">
                    Learn more
                  </button>
                </Link>
              </div>
            </Fade>
          </div>

          <Fade left>
            <div className="lg:hidden  block">
              <Image
                src={`/images/api.png`}
                priority={true}
                height={400}
                width={800}
                className="rounded-md shadow-lg"
              />
            </div>
          </Fade>
        </div>
      </section>
      <section className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="lg:pr-10 ">
            <Fade left>
              <a className="inline-block mb-5">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-primary-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-primary-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                    />
                  </svg>
                </div>
              </a>
            </Fade>
            <Fade left>
              <h1 className="mb-4 section-heading">Automated Workflows</h1>
            </Fade>
            <Fade left>
              <p className="mb-6 mt-6 section-para">
                Powerful automations and tools to help your team spend less time
                managing inventory. Save precious time and manpower to spend on
                developing new products and speaking to customers
              </p>

              <div className="flex items-center space-x-4">
                <Link href={"/solutions"}>
                  <button className="inline-flex items-center font-semibold transition-colors duration-200 text-primary-600 ">
                    Learn more
                  </button>
                </Link>
              </div>
            </Fade>
          </div>

          <Fade right>
            <div>
              <Image
                src={`/images/33.png`}
                priority={true}
                height={400}
                width={800}
                className="rounded-md shadow-2xl"
              />
            </div>
          </Fade>
        </div>
      </section>
      <section className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <Fade top>
            <h2 className="max-w-lg mb-6 text-center  text-gray-900 section-heading md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-primary-300 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative">How</span>
              </span>{" "}
              you Benefit
            </h2>
          </Fade>
        </div>
        <div className="grid gap-8 mb-8 lg:grid-cols-3 sm:grid-cols-1">
          {[
            {
              title: "Distribute Globally",
              description: `Make your products available to the right consumers on
                        their preferred channels globally`,
              img: `benefit-1.svg`
            },
            {
              title: "Expand Product Offering",
              description: `Give your customers thousands of tour products ready to be
                    booked on your website`,
              img: `benefit-2.svg`
            },
            {
              title: "Boost Sales",
              description: `Increase profit margins and sales with attractive
                    commissions`,
              img: `benefit-3.svg`
            },
            {
              title: "No Additional Resources",
              description: `We manage all supplier connections, allowing you to spend
                    more time on sales and marketing`,
              img: `benefit-4.svg`
            },
            {
              title: "No More Spreadsheets",
              description: `Ditch spreadsheets and offline itineraries for cloud-base
                    reservation system that digitalises your business`,
              img: `benefit-5.svg`
            },
            {
              title: "Online Booking",
              description: `Instantly turn your website traffic into sales with
                    automatic online tour bookings`,
              img: `benefit-6.svg`
            }
          ]?.map(item => {
            return (
              <div
                key={item.title}
                className="inline-block overflow-hidden duration-300 transform bg-white  hover:-translate-y-2"
              >
                <Fade bottom>
                  <div className="flex flex-col h-full">
                    <Image
                      src={`/images/${item.img}`}
                      height={256}
                      width={256}
                    />
                    <div className="flex-grow text-center ">
                      <div className="p-5">
                        <h6 className="mb-2 font-semibold text-lg">
                          {item.title}
                        </h6>
                        <p className="text-sm text-gray-900">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            );
          })}
        </div>
      </section>

      <section className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-12">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <Fade top>
            <h2 className="max-w-lg mb-6 text-center  text-gray-900 section-heading md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-primary-300 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative">Our</span>
              </span>{" "}
              Partners
            </h2>
          </Fade>
        </div>
        <div className="max-w-7xl mx-auto pb-20 px-4  sm:px-6 lg:px-8">
          <div className=" mt-8 ">
            <Fade bottom>
              <div className="grid grid-cols-2 lg:grid-cols-5 gap-6 mt-16">
                {["l1.png", "l2.png", "l3.png", "l4.png", "l5.png"]?.map(
                  logo => {
                    return (
                      <div key={logo} className="mt-4 col-span-1 mx-auto">
                        <img
                          className="h-14 w-22"
                          src={`/images/partners/${logo}`}
                          alt="logo"
                        />
                      </div>
                    );
                  }
                )}
              </div>
              <Link href={"/partners"}>
                <button className="mx-auto flex items-center justify-center  pt-12 font-semibold transition-colors duration-200 text-primary-600 ">
                  View All
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 ml-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
              </Link>
            </Fade>
          </div>
        </div>
      </section>

      <section className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <Fade top>
            <h2 className="max-w-lg mb-6 text-center  text-gray-900 section-heading md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-primary-300 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative">Why</span>
              </span>{" "}
              choose Twurs
            </h2>
          </Fade>
        </div>
        <div className="grid gap-5 row-gap-5 mb-8 lg:grid-cols-3 grid-cols-1 ">
          {[
            {
              title: "Zero Set Up Cost",
              description:
                "No set up cost for suppliers and resellers. Minimal fee to upgrade",
              img: `choose-1.svg`
            },
            {
              title: "Fast Set Up",
              description:
                "Start selling immediately and API connect to us within a week",
              img: `choose-2.svg`
            },
            {
              title: "Easy to Use",
              description: "Easy to onboard new user and high satisfaction",
              img: `choose-3.svg`
            },
            {
              title: "Speed & Stability",
              description:
                "We use cloud based reservation system for optimum performance",
              img: `choose-4.svg`
            },
            {
              title: "Modular Platform",
              description:
                "Compatible platform for tours, activities, transfer & staycations",
              img: `choose-5.svg`
            }
          ]?.map(item => {
            return (
              <a
                key={item.title}
                aria-label="View Item"
                className="inline-block col-span-1 overflow-hidden duration-300 transform bg-white  hover:-translate-y-2"
              >
                <Fade bottom>
                  <div className="flex flex-col h-full">
                    <Image
                      src={`/images/${item.img}`}
                      height={256}
                      width={256}
                    />
                    <div className="flex-grow  text-center ">
                      <div className="p-5">
                        <h6 className="mb-2 font-semibold text-lg">
                          {item.title}
                        </h6>
                        <p className="text-sm text-gray-900">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Fade>
              </a>
            );
          })}
        </div>
      </section>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
          <div className="bg-gray-800 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-5 lg:gap-4">
            <div className="col-span-1 lg:col-span-3 pt-10 pb-12 flex justify-center items-center px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-12">
              <Fade left>
                <div className="lg:self-center">
                  <h2 className=" text-white text-2xl md:text-4xl section-heading ">
                    <span className="block">Ready to dive in?</span>
                    <span className="block">Start 100% risk-free today.</span>
                  </h2>

                  <p className="mt-4 section-para text-gray-300">
                    Signing up for an account takes less than 10 minutes. Take
                    the first step in futurising your business today with Twurs.
                  </p>
                  <Link href={`/signup/supplier`}>
                    <button className="mt-8 mr-3 w-full md:w-auto bg-primary-600 border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-white hover:bg-primary-700">
                      Sign up as Supplier
                    </button>
                  </Link>
                  <Link href={`/signup/reseller`}>
                    <button className="mt-8 bg-white w-full md:w-auto border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-primary-600 hover:bg-primary-50">
                      Sign up as Reseller
                    </button>
                  </Link>
                  <h2 className=" mt-8 flex items-center font-medium text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Got any queries? Fill in the form and we will reach out to
                    you soon.
                  </h2>
                </div>
              </Fade>
            </div>

            <div className="col-span-1 lg:col-span-2 flex justify-start px-4 md:px-0 lg:px-0 items-center lg:justify-center">
              <div className="w-full  md:w-3/4  lg:w-full px-0 md:px-20 lg:px-8 py-6">
                <Fade right>
                  <div className="bg-white rounded shadow-2xl p-7 ">
                    <form>
                      <div className="mb-1  sm:mb-2">
                        <label
                          htmlFor="name"
                          className="inline-block text-sm mb-1 font-medium"
                        >
                          Name
                        </label>
                        <input
                          required
                          onChange={e =>
                            setState(state => ({
                              ...state,
                              formData: {
                                ...state.formData,
                                name: e.target.value
                              }
                            }))
                          }
                          value={state.formData.name}
                          type="text"
                          className="text-sm flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-primary-400 focus:outline-none focus:shadow-outline"
                          id="name"
                          name="name"
                        />
                      </div>
                      <div className="mb-1  sm:mb-2">
                        <label
                          htmlFor="phone"
                          className="inline-block text-sm mb-1 font-medium"
                        >
                          Phone
                        </label>
                        <input
                          required
                          onChange={e =>
                            setState(state => ({
                              ...state,
                              formData: {
                                ...state.formData,
                                phone: e.target.value
                              }
                            }))
                          }
                          value={state.formData.phone}
                          type="text"
                          className="text-sm flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-primary-400 focus:outline-none focus:shadow-outline"
                          id="phone"
                          name="phone"
                        />
                      </div>
                      <div className="mb-1 sm:mb-2">
                        <label
                          htmlFor="email"
                          className="inline-block text-sm mb-1 font-medium"
                        >
                          E-mail
                        </label>
                        <input
                          required
                          onChange={e =>
                            setState(state => ({
                              ...state,
                              formData: {
                                ...state.formData,
                                email: e.target.value
                              }
                            }))
                          }
                          value={state.formData.email}
                          type="text"
                          className="text-sm flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-primary-400 focus:outline-none focus:shadow-outline"
                          id="email"
                          name="email"
                        />
                      </div>
                      <div className="mb-1 sm:mb-2">
                        <label
                          htmlFor="message"
                          className="text-sm inline-block mb-1 font-medium"
                        >
                          Message
                        </label>
                        <textarea
                          required
                          onChange={e =>
                            setState(state => ({
                              ...state,
                              formData: {
                                ...state.formData,
                                message: e.target.value
                              }
                            }))
                          }
                          value={state.formData.message}
                          className="text-sm resize-y flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-primary-400 focus:outline-none focus:shadow-outline"
                          id="message"
                          name="message"
                        />
                      </div>
                      <div className="mt-4 mb-2 sm:mb-4">
                        {!state.msgSubmitted ? (
                          <div
                            onClick={async () => {
                              const { name, email, phone, message } =
                                state.formData;
                              if (name && email && phone && message) {
                                await firebaseApp
                                  .functions()
                                  .httpsCallable("sendHomeContactEmail")(
                                  state.formData
                                );

                                setState(state => ({
                                  ...state,
                                  formData: defaultState.formData,
                                  msgSubmitted: true
                                }));
                              }
                            }}
                            className="col-span-1 rounded-md shadow"
                          >
                            <a className="cursor-pointer w-full flex items-center justify-center px-8 py-3 border border-transparent text-md font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 md:py-4  md:px-6">
                              Submit
                            </a>
                          </div>
                        ) : (
                          <div className="col-span-1 rounded-md shadow">
                            <button className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 md:py-4  md:px-6">
                              We'll get back to you soon!
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const HeroSection = ({ title, description, imgg }) => {
  return (
    <section className="h-auto select-none relative ">
      <div className=" absolute inset-0 ">
        <img
          className="hidden lg:block lg:h-full lg:w-full lg:object-cover"
          src="https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        />
        <div className="hidden lg:block lg:absolute lg:inset-0 lg:bg-gray-600 lg:mix-blend-multiply" />
      </div>

      <div className="max-w-7xl  grid grid-cols-1 lg:grid-cols-2 mx-auto">
        <div className="text-left sm:text-center lg:text-left col-span-1 relative w-full pt-16 pb-8  lg:py-48 ">
          <div className="px-4 sm:px-8 xl:pr-16">
            <Fade top>
              <h1 className=" text-gray-900 lg:text-white section-heading">
                {title}
              </h1>
            </Fade>

            <p className="mt-3 w-full mx-0 sm:mx-auto lg:mx-0  section-para text-gray-600 lg:text-gray-400  md:mt-5 max-w-3xl ">
              {description}
            </p>

            <div className="mx-0 sm:mx-auto lg:mx-0 mt-6 grid grid-cols-1 xl:grid-cols-2 max-w-lg gap-4">
              <div className="col-span-1 rounded-md shadow">
                <Link href={`/signup/supplier`}>
                  <button className="w-full flex items-center justify-center px-8 py-3  text-md font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 md:py-4  md:px-6">
                    Supplier Registration
                  </button>
                </Link>
              </div>
              <div className="col-span-1 rounded-md shadow">
                <Link href={`/signup/reseller`}>
                  <button className="w-full flex items-center justify-center px-8 py-3  text-md font-medium rounded-md text-primary-600 bg-white hover:bg-gray-50 md:py-4  md:px-6">
                    Become a Reseller
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className=" col-span-1 flex justify-center items-center">
          <div className="h-auto lg:h-80 py-6 lg:py-0 lg:mb-8">
            <Image
              src={`/images/${imgg}`}
              priority={true}
              width={600}
              height={420}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
